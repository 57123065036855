import gql from 'graphql-tag';

export const PageSelectorDefaultPageQuery = gql`
	query PageSelectorDefaultPageQuery($defaultPageId: ID!) {
		defaultPage: content(id: $defaultPageId) {
			nodes {
				id
				title
				operations {
					operation
					targetType
				}
				container {
					id
					key
					operations {
						operation
						targetType
					}
					icon {
						path
						width
						height
						isDefault
					}
				}
			}
		}
	}
`;
