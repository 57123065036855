import React, { Component } from 'react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';

export class QueryGroup extends Component {
	static propTypes = {
		queryProps: PropTypes.array.isRequired,
		children: PropTypes.func.isRequired,
	};

	_queryResults = [];
	_queryProperties = [];

	_anyLoading = () => this._queryResults.some((data) => data.loading === true);

	_renderQueryComponent = (configArray, children, index) => {
		if (!configArray.length) {
			return children({
				results: [],
				loading: false,
			});
		}
		return (
			<Query errorPolicy="all" {...configArray[index]}>
				{({ data, loading, error, ...args }) => {
					this._queryResults[index] = { data, loading, error };
					this._queryProperties[index] = args;
					const nextIndex = index + 1;
					if (configArray.length > nextIndex) {
						return this._renderQueryComponent(configArray, children, nextIndex);
					}

					return children({
						results: this._queryResults,
						loading: this._anyLoading(),
						properties: this._queryProperties,
					});
				}}
			</Query>
		);
	};

	render() {
		const { queryProps, children } = this.props;
		return this._renderQueryComponent(queryProps, children, 0);
	}
}
