import gql from 'graphql-tag';

export const PageSelectorQuery = gql`
	query PageSelectorQuery($cql: String!, $first: Int = 15) {
		search(cql: $cql, first: $first) {
			count
			nodes {
				content {
					id
					title
					type
					operations {
						operation
						targetType
					}
					container {
						id
						key
						operations {
							operation
							targetType
						}
						icon {
							path
							width
							height
							isDefault
						}
					}
				}
			}
		}
	}
`;
