import gql from 'graphql-tag';

export const PageSelectorAllPagesInSpaceQuery = gql`
	query PageSelectorAllPagesInSpaceQuery(
		$spaceKey: String!
		$first: Int = 15
		$navigationType: String
	) {
		allPages: content(
			spaceKey: $spaceKey
			orderby: "history.by.when desc"
			first: $first
			status: ["current"]
			navigationType: $navigationType
		) {
			nodes {
				id
				title
				type
				operations {
					operation
					targetType
				}
				container {
					id
					key
					operations {
						operation
						targetType
					}
					icon {
						path
						width
						height
						isDefault
					}
				}
			}
		}
	}
`;
