import React, { PureComponent } from 'react';
import debounce from 'lodash/debounce';
import idx from 'idx';
import type { WrappedComponentProps, MessageDescriptor } from 'react-intl-next';
import { injectIntl, defineMessages } from 'react-intl-next';
import type { QueryOptions } from 'apollo-client';

import withAnalyticsEvents from '@atlaskit/analytics-next/withAnalyticsEvents';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';
import type { StylesConfig } from '@atlaskit/select';

import { QueryGroup } from '@confluence/query-group';
import { debouncePromise } from '@confluence/debounce';
import { ErrorDisplay } from '@confluence/error-boundary';

import type { PageType, OptionType } from './PageSelectorComponent';
import { PageSelectorComponent, EMPTY_PAGE_ID, EMPTY_SPACE_KEY } from './PageSelectorComponent';
import { PageSelectorQuery } from './PageSelectorQuery.graphql';
import { PageSelectorDefaultPageQuery } from './PageSelectorDefaultPageQuery.graphql';
import { PageSelectorAllPagesInSpaceQuery } from './PageSelectorAllPagesInSpaceQuery.graphql';

const DEFAULT_PAGE_LIMIT = 15;

const noop = () => {};

const i18n = defineMessages({
	recentPages: {
		id: 'page-selector.recent.pages',
		defaultMessage: 'Recent',
		description:
			'The title header inside Parent Selector indicating the content below are recent contents modified by user',
	},
	allPagesInSpace: {
		id: 'page-selector.all.pages.in.space',
		defaultMessage: 'All content in this space',
		description:
			'The title header inside Parent Selector when user search for content inside spaces',
	},
	noParentPage: {
		id: 'page-selector.no-parent-page-selection',
		defaultMessage: 'No parent',
		description: 'The option to select no parent',
	},
});

export const DEFAULT_QUERY_ARGS: Omit<QueryOptions, 'query'> = {
	// @TODO, this query should be able to use "cache-first" fetch policy like the others,
	// but currently container id field on graphql search endpoint is broken
	// which in turn breaks Apollo cache and prevents us from returning search
	// results. Use no-cache policy to skip cache altogether and avoid this
	// problem, until that field is fixed.
	fetchPolicy: 'no-cache',
	errorPolicy: 'all',
	context: { single: true }, // to prevent batching
};

type PageSelectorProps = {
	onChange: (page: PageType) => void;
	spaceKey: string;
	defaultPageId?: string | null;
	defaultPageOptionDescriptor?: MessageDescriptor;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	showLoading?: boolean;
	isDisabled?: boolean;
	filterOption?: ({ value }: { value: any }) => boolean;
	useMenuPortalTarget?: boolean;
	selectStyleOverrides?: StylesConfig<OptionType>;
	htmlFor?: string;
	isWhiteboardInContentTreeEnabled?: boolean;
	isDatabaseCreationEnabled?: boolean;
	isFolderEnabled?: boolean;
	requireParent?: boolean;
};
export const PageSelector = withAnalyticsEvents()(
	injectIntl(
		class PageSelector extends PureComponent<PageSelectorProps & WrappedComponentProps> {
			static defaultProps = {
				onChange: noop,
				useMenuPortalTarget: false,
				selectStyleOverrides: {},
				htmlFor: '',
			};

			state = {
				pageQuery: '',
				selectedPage: null,
				error: undefined,
			};

			componentWillUnmount() {
				this.handleFilterChange.cancel();
			}

			_handleFilterChange = (value) => {
				const { spaceKey } = this.props;

				this.fireAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						source: 'pageSelector',
						actionSubjectId: 'pageSelectorDropdown',
						actionSubject: 'pageSelectorDropdownSearch',
						action: 'search',
						attributes: {
							query: value,
							spaceKey,
						},
					},
				});

				this.setState({ pageQuery: value, error: undefined });
			};

			handleFilterChange = debounce(this._handleFilterChange, 250);

			getPageSearchCql = (options) => {
				const {
					keyword,
					spaceKey,
					isWhiteboardInContentTreeEnabled,
					isDatabaseCreationEnabled,
					isFolderEnabled,
				} = options;

				const types = ['page'];

				if (isWhiteboardInContentTreeEnabled) {
					types.push('whiteboard');
				}
				if (isDatabaseCreationEnabled) {
					types.push('database');
				}
				if (isFolderEnabled) {
					types.push('folder');
				}

				let cql = `type IN (${types.join(', ')})`;

				if (keyword) {
					cql += ` AND (title ~ '${keyword.replace(/\'/g, '%27')}*'
          OR title ~ '${keyword.replace(/\'/g, '%27')}')`;
				}

				cql += ` AND space.key = '${spaceKey}'
                 order by lastModified desc`;
				return cql;
			};

			fireAnalyticsEvent = (event) => {
				this.props.createAnalyticsEvent(event).fire();
			};

			processSearchData = (data: any = {}) =>
				this.processContent(
					(idx(data, (_) => _.search.nodes) || []).map((node) => idx(node, (_) => _.content) || {}),
				);

			processPageData = (data: any = {}) => this.processContent(idx(data, (_) => _.nodes) || []);

			processContent = (data: any = {}) =>
				data.map((content) => ({
					id: content.id,
					contentId: content.id,
					title: content.title,
					type: content.type,
					spaceKey: idx(content, (_) => _.container.key),
					spaceIcon: idx(content, (_) => _.container.icon.path),
					spaceOperations: idx(content, (_) => _.container.operations),
					pageOperations: idx(content, (_) => _.operations),
					descriptor:
						this.props.defaultPageOptionDescriptor && content.id === this.props.defaultPageId
							? this.props.intl.formatMessage(this.props.defaultPageOptionDescriptor)
							: undefined,
				}));

			loadOptions = (
				refetchPages,
				allPages,
				spaceKey,
				inputValue,
				isWhiteboardInContentTreeEnabled,
				isDatabaseCreationEnabled,
				isFolderEnabled,
			) => {
				return refetchPages({
					cql: this.getPageSearchCql({
						keyword: inputValue,
						spaceKey,
						isWhiteboardInContentTreeEnabled,
						isDatabaseCreationEnabled,
						isFolderEnabled,
					}),
				})
					.then((response) => {
						const { data, error } = response;

						if (error) {
							return [
								{
									label: this.props.intl.formatMessage(i18n.recentPages),
									options: [],
								},
							];
						}

						const pagesSearched = this.processSearchData(data);

						return [
							{
								label: this.props.intl.formatMessage(i18n.allPagesInSpace),
								options: inputValue && inputValue.length > 0 ? pagesSearched : allPages,
							},
						];
					})
					.catch((error) => {
						this.setState({ error });
					});
			};

			getQueryProps = (spaceKey, defaultPageId) => {
				const { isWhiteboardInContentTreeEnabled, isDatabaseCreationEnabled, isFolderEnabled } =
					this.props;

				const baseQueries: any[] = [
					{
						...DEFAULT_QUERY_ARGS,
						query: PageSelectorQuery,
						variables: {
							cql: this.getPageSearchCql({
								keyword: this.state.pageQuery,
								spaceKey,
								first: DEFAULT_PAGE_LIMIT,
								isWhiteboardInContentTreeEnabled,
								isDatabaseCreationEnabled,
								isFolderEnabled,
							}),
						},
					},
					{
						query: PageSelectorAllPagesInSpaceQuery,
						variables: {
							spaceKey,
							limit: DEFAULT_PAGE_LIMIT,
							...(isWhiteboardInContentTreeEnabled || isDatabaseCreationEnabled || isFolderEnabled
								? {
										navigationType: 'contentTree',
									}
								: {}),
						},
						errorPolicy: 'all',
						fetchPolicy: 'cache-first',
						context: { single: true }, // to prevent batching
					},
				];
				if (defaultPageId) {
					baseQueries.push({
						query: PageSelectorDefaultPageQuery,
						variables: {
							defaultPageId,
						},
						errorPolicy: 'all',
						fetchPolicy: 'cache-first',
						context: { single: true }, // to prevent batching
					});
				}
				return baseQueries;
			};

			onChange = (page: PageType) => {
				// @ts-ignore
				if (page.id === this.state.selectedPage?.id) {
					return;
				}

				this.setState({
					selectedPage: page,
				});

				this.props.onChange(page);
			};

			noPageSelectionOption = {
				title: this.props.intl.formatMessage(i18n.noParentPage),
				spaceKey: EMPTY_SPACE_KEY,
				id: EMPTY_PAGE_ID,
				contentId: EMPTY_PAGE_ID,
				spaceIcon: '/images/logo/default-space-logo-256.png',
			};

			render() {
				const { pageQuery } = this.state;
				const {
					spaceKey,
					defaultPageId,
					useMenuPortalTarget,
					isWhiteboardInContentTreeEnabled,
					isDatabaseCreationEnabled,
					isFolderEnabled,
				} = this.props;

				return (
					<QueryGroup queryProps={this.getQueryProps(spaceKey, defaultPageId)}>
						{({ results, properties }) => {
							const [
								pagesSearchedWithinSpaceResponse,
								allPagesWithinSpaceResponse,
								defaultPageResponse,
							] = results;

							const [pagesSearchedWithinSpaceProperties] = properties;

							const pagesSearched = this.processSearchData(
								idx(pagesSearchedWithinSpaceResponse, (_) => _.data),
							);

							const allPages = this.processPageData(
								idx(allPagesWithinSpaceResponse, (_) => _.data.allPages),
							);

							const defaultPages = this.processPageData(
								idx(defaultPageResponse, (_) => _.data.defaultPage),
							);

							let defaultPage: PageType = this.noPageSelectionOption;
							// show page selection if user has selected a page
							if (this.state.selectedPage) {
								defaultPage = this.state.selectedPage!;

								// otherwise use the default page requested by defaultPageId prop
							} else if (defaultPages.length) {
								defaultPage = defaultPages[0];
							}

							const items: any = [];
							if (!this.props.requireParent) {
								items.push({
									label: '',
									options: [this.noPageSelectionOption],
									dataTestID: 'no-page-selection',
								});
							}
							items.push({
								label: this.props.intl.formatMessage(i18n.recentPages),
								options: pageQuery && pageQuery.length > 0 ? pagesSearched : allPages,
								dataTestID: 'recent-pages',
							});
							const { refetch } = pagesSearchedWithinSpaceProperties;

							const { loading: loadingPages } = allPagesWithinSpaceResponse;

							return (
								<>
									{this.state.error ? <ErrorDisplay error={this.state.error!} /> : null}
									<PageSelectorComponent
										useMenuPortalTarget={useMenuPortalTarget}
										selectStyleOverrides={this.props.selectStyleOverrides}
										intl={this.props.intl}
										fireAnalyticsEvent={this.fireAnalyticsEvent}
										onInputChange={this.handleFilterChange}
										isLoading={loadingPages || this.props.showLoading}
										isDisabled={this.props.isDisabled}
										onChange={this.onChange}
										defaultOptions={items}
										defaultPage={defaultPage}
										loadOptions={debouncePromise((inputValue) => {
											return this.loadOptions(
												refetch,
												allPages,
												spaceKey,
												inputValue,
												isWhiteboardInContentTreeEnabled,
												isDatabaseCreationEnabled,
												isFolderEnabled,
											);
										}, 500)}
										filterOption={this.props.filterOption}
										error={this.state.error}
										htmlFor={this.props.htmlFor}
									/>
								</>
							);
						}}
					</QueryGroup>
				);
			}
		},
	),
);
